import { Injectable } from '@angular/core';
import {
  BaseDataApiModel,
  BaseDataService as CompleteBaseDataService,
  BaseDataValueApiModel,
  BaseDataValueIndexedDbModel,
} from '@capturum/complete';
import { from, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { isEmpty, sortBy } from 'lodash';
import { ApiHttpService } from '@capturum/api';
import { BaseKey } from '@core/enums/base-key.enum';
import { BaseDataValueMapItem, MapItem as ReyersenMapItem } from '@shared/models/mapItem.model';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService extends CompleteBaseDataService {
  protected dataSet = new Map();
  protected baseDataMap = new Map<string, BaseDataApiModel>();
  protected moduleKey = 'reyersen_van_buuren';

  constructor(
    apiHttp: ApiHttpService,
    protected translateService: TranslateService,
  ) {
    super(apiHttp);
  }

  public listBaseData(key: string): Observable<BaseDataValueMapItem[]> {
    return this.getBaseDataValuesByKey(key).pipe(this.prepareBaseDataValues.bind(this));
  }

  public getBaseDataValuesWithAttributes(key: BaseKey): Observable<ReyersenMapItem[]> {
    return this.getBaseDataValuesByKey(key).pipe(
      map((baseDataValues) => {
        return baseDataValues.map((baseDataValue) => {
          return {
            label: this.translateService.instant(`base-data.${baseDataValue.id}`),
            value: baseDataValue.id,
            key: baseDataValue.value,
            parent_id: baseDataValue.parent_id,
            sort: baseDataValue['order'] || 0,
            attributes: {
              preselected: this.getBaseItemAttributeValue('pre-selected', baseDataValue) === 'true',
              selectable: this.getBaseItemAttributeValue('selectable', baseDataValue) === 'true',
            },
          } as any as ReyersenMapItem;
        });
      }),
    );
  }

  public getBaseDataValuesByKey(key: string): Observable<BaseDataValueApiModel[]> {
    return of(
      this.getBaseDataArray().filter((value) => {
        return value.base_data_key === `${this.moduleKey}.${key}` || value['key'] === key;
      }),
    ).pipe(
      map((baseDataValues) => {
        return sortBy(baseDataValues, 'order');
      }),
    );
  }

  public getBaseDataValuesByKeyAndAttribute(
    key: string,
    attributeKey: string = null,
    attributeValue: any = null,
  ): Observable<BaseDataValueMapItem[]> {
    return this.getBaseDataValuesByKey(key).pipe(
      map((baseDataValues) => {
        const items = [];

        baseDataValues.forEach((baseDataValue) => {
          if (
            this.hasAttributeByKeyAndValue(baseDataValue, attributeKey, attributeValue) ||
            (!attributeKey && !attributeValue)
          ) {
            items.push({
              ...baseDataValue,
              ...this.convertBaseDataValueToMapItem({
                id: baseDataValue.id,
                value: baseDataValue.value,
              } as BaseDataValueApiModel),
            } as BaseDataValueMapItem);
          }
        });

        return sortBy(items, 'order');
      }),
    );
  }

  public getBaseDataKeyId(key: string): Observable<string> {
    return this.getBaseDataValuesByKey(key).pipe(
      map((baseDataValues) => {
        return baseDataValues[0]?.base_data_key_id || null;
      }),
    );
  }

  public getById(id: string): Observable<BaseDataValueMapItem> {
    const result = of(this.baseDataMap.get(id));

    return from(result).pipe(
      map((data: BaseDataApiModel) => {
        return {
          ...data,
          label: this.getBaseDataValueTranslation(data),
        };
      }),
    );
  }

  public loadBaseDataInMap(): Observable<boolean> {
    this.baseDataMap = new Map();

    return this.apiHttp.get('/role/base-data').pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response.data.map((baseDataValue) => {
          return this.transformBaseDataValue(baseDataValue);
        });
      }),
      tap((response) => {
        response.forEach((baseDataValue) => {
          this.baseDataMap.set(baseDataValue.id, baseDataValue);
        });
      }),
      map(() => {
        return true;
      }),
    );
  }

  protected getBaseDataValueTranslation(baseDataValue: BaseDataValueApiModel | BaseDataApiModel): string {
    return baseDataValue?.id ? this.translateService.instant(`base-data.${baseDataValue.id}`) : null;
  }

  protected convertBaseDataValueToMapItem(baseDataValue: BaseDataValueApiModel): BaseDataValueMapItem {
    return {
      label: this.getBaseDataValueTranslation(baseDataValue),
      value: baseDataValue.id,
      key: baseDataValue.value,
    };
  }

  protected getBaseDataArray(): BaseDataApiModel[] {
    return [...this.baseDataMap.values()];
  }

  protected hasAttributeByKeyAndValue(baseDataValue: any, attributeKey: string, attributeValue: string): boolean {
    if (attributeKey && attributeValue && !isEmpty(baseDataValue.attributes)) {
      return !!baseDataValue.attributes.find((attribute) => {
        if (attribute.attribute.data_type.type === 'boolean') {
          attribute.value = attribute.value === 'true' || attribute.value === true;
        }

        return attribute.attribute.key === attributeKey && attribute.value === attributeValue;
      });
    }

    return false;
  }

  protected prepareBaseDataValues(source: Observable<any>): Observable<BaseDataValueMapItem[]> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return new Observable((subscriber) => {
      source.subscribe({
        next(values: BaseDataValueIndexedDbModel[]): void {
          if (values) {
            subscriber.next(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              self.unflattenBaseDataValueChildren(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                values.map((baseDataValue: BaseDataValueApiModel) => {
                  return {
                    ...self.convertBaseDataValueToMapItem(baseDataValue),
                    parent_id: baseDataValue.parent_id,
                    attributes: baseDataValue.attributes,
                  };
                }),
              ),
            );
          } else {
            subscriber.next([]);
          }

          subscriber.complete();
        },
      });
    });
  }

  protected unflattenBaseDataValueChildren(list: BaseDataValueApiModel[]): BaseDataValueApiModel[] {
    const children = [];

    for (const item of list) {
      if (item.parent_id) {
        continue;
      }

      const groupItem = { ...item, children: [] };

      for (const subItem of list) {
        if (subItem.parent_id !== groupItem.value) {
          continue;
        }

        groupItem.children.push({ ...subItem });
      }

      children.push(groupItem);
    }

    return children;
  }

  protected getBaseItemAttributeValue(
    property: string,
    baseDataValue: BaseDataValueApiModel,
  ): string | number | boolean {
    if (baseDataValue.attributes) {
      for (const attribute of baseDataValue.attributes) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (attribute.attribute.key === property) {
          return attribute.value;
        }
      }
    }

    return null;
  }
}
